import classes from './PricingBlock.module.css'; 
import { FaCheck } from 'react-icons/fa';

const pageHandler = () => {
    window.open("https://diracai.com/services/", "_blank");
};
const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',  
    });
}



function PricingBlock() {
    return (
        <div className={classes.app}>
         
           
          
            <div className={classes.heroContainer}>
                <div className={classes.heroHeading}>
                    <div className={classes.headCnt}>Pricing</div>
                </div>

                <div className={classes.heroMain}>
                    <div className={classes.mainTop}>

                        <div className={classes.firstBox}>
                         
                            <div className={classes.info}>
                                <div className={classes.time}>BASIC</div>
                                {/* <div className={classes.faculty}>By prashant Sir</div> */}
                            </div>

                            <div className={classes.middleBlock}>
                            <div className={classes.rs}><span className={classes.dollar}>₹</span> 0 <span className={classes.span}>/month</span> </div>

<div className={classes.div}> 
                            <div className={classes.innerDiv}><span><FaCheck /></span>Suitable for small groups.
                            </div>
                            <div className={classes.innerDiv}><span><FaCheck /></span>Access to  basic support. </div>
                            <div className={classes.innerDiv}><span><FaCheck /></span> Basic storage options. </div>
                            {/* <div><FaCheck />Advertisements may be displayed. </div> */}
                            <div className={classes.innerDiv}><span><FaCheck /></span>Access to core functionalities </div>
                            </div>

                            </div>

                                <button className={classes.enrollNow}>Buy Now</button>
                        </div>


                        <div className={classes.secondBox}>
                         
                            <div className={classes.info}>
                                <div className={classes.time}>STANDARD</div>
                                {/* <div className={classes.faculty}>By Ashok Sir</div> */}
                            </div>
                            <div className={classes.middleBlock}>
                            <div className={classes.rs}><span className={classes.dollar}>₹</span> 100 <span className={classes.span}>/month</span> </div>


                                <div className={classes.div}> 
                            <div className={classes.innerDiv}><span><FaCheck /></span>Full access to  advanced functionalities.</div>
                            <div className={classes.innerDiv}><span><FaCheck /></span>Ideal for larger  small organizations.</div>
                            <div className={classes.innerDiv}><span><FaCheck /></span>Faster response  dedicated support.</div>
                            {/* <div><FaCheck />Additional security measures and data encryption.</div> */}
                            <div className={classes.innerDiv}><span><FaCheck /></span>More storage  for your projects.</div>
                            </div>

                            </div>
                            <button className={classes.enrollNow}>Buy Now</button>
                        </div>


                        <div className={classes.thirdBox}>
                       
                            <div className={classes.info}>
                                <div className={classes.time}>PREIMIUM</div>
                                {/* <div className={classes.faculty}>By Maharana classes</div> */}
                            </div>
                            <div className={classes.middleBlock}>
                            <div className={classes.rs}> <span className={classes.dollar}>₹</span> 200 <span className={classes.span}>/month</span> </div>

                                <div className={classes.div}> 
                            <div className={classes.innerDiv}><span><FaCheck /></span> Advanced project management tools.</div>
                            <div className={classes.innerDiv}><span><FaCheck /></span>Suitable for  and enterprises</div>
                            <div className={classes.innerDiv}><span><FaCheck /></span>Around-the-clock premium support.</div>
                            {/* <div><FaCheck /> Integration with third-party apps and custom APIs.</div> */}
                            <div className={classes.innerDiv}><span><FaCheck /></span>Personalized assistance .</div>
                            </div>

                            </div>
                            <button className={classes.enrollNow}>Buy Now</button>
                        </div>


                    </div>

                
 
                </div>
            </div>

          


 
        </div>
    );
}
export default PricingBlock;
