


import classes from './Logo.module.css';
const Logo=()=>{


return (
      <div className={classes.logoText}>
          Di
          <div className={classes.styleBar}> </div>
      </div>


);	



}

export default Logo;
